import React from 'react';

import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Img from 'gatsby-image';
import HeroTwo from './HeroTwo';
import earth from '../images/nasa-Q1p7bh3SHj8-unsplash.jpg';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import GitHubIcon from '@material-ui/icons/GitHub';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
export const useStyles = makeStyles((theme) => ({
  header: {
    ...theme.typography.headerTwo,
    textAlign: 'center',
  },
  headerTwo: {
    ...theme.typography.headerThree,
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      width: '67%',
      '&:nth-of-type(even)': {
        marginLeft: 'auto',
      },
      '&+p': {
        width: '67%',
        display: 'inline-block',
        verticalAlign: 'top',
      },
      '&+img': {
        '&+p': {
          width: '67%',
          display: 'inline-block',
          verticalAlign: 'top',
        },
      },
    },
  },
  vector: {
    width: '30%',
    padding: '0 1.5%',
    display: 'inline-block',
    verticalAlign: 'top',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      display: 'block',
      margin: '0 auto',
    },
  },
  body: {
    ...theme.typography.body,
    textAlign: 'left',
    marginBottom: '2rem',
  },
  listItem: {
    ...theme.typography.listItem,
  },
  pageWrapper: {
    position: 'relative',
    marginTop: '2rem',
  },
  richTextWrapper: {
    marginTop: '5rem',
  },
  resultSection: {
    width: '100%',
    height: '50vh',
    backgroundImage: `linear-gradient(to bottom,rgba(34,40,49,1), rgba(34,40,49,.85)), url('${earth}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    clipPath: 'polygon(0 10vh,100% 0, 100% 100%, 0 100%)',
    position: 'absolute',
    bottom: 0,
    zIndex: 0,
    [theme.breakpoints.down('md')]: {
      height: '20vh',
      clipPath: 'polygon(0 5vh,100% 0, 100% 100%, 0 100%)',
    },
  },
  resultWrapper: {
    maxWidth: '55rem',
    margin: '3rem auto',
    zIndex: 1,
    translate: '',
  },
  result: {
    zIndex: 20,
  },
  fabWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    position: 'fixed',
    top: '5rem',
    right: '2rem',
    '&:first-child': {
      marginBottom: '1rem',
    },
    zIndex: 100000,
    [theme.breakpoints.down('md')]: {
      right: '1.5rem',
    },
  },
  fab: {
    marginBlock: '1rem',
  },
  fabIcon: {
    marginRight: '.5rem',
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
}));

export const Bold = ({ children }) => {
  const classes = useStyles();
  return <span className={classes.bold}>{children}</span>;
};
export const Italics = ({ children }) => {
  const classes = useStyles();
  return <span className={classes.italics}>{children}</span>;
};
export const Text = ({ children }) => {
  const classes = useStyles();
  return <p className={classes.body}>{children}</p>;
};
export const Heading = ({ children }) => {
  const classes = useStyles();
  return <h2 className={classes.header}>{children}</h2>;
};
export const HeadingTwo = ({ children }) => {
  const classes = useStyles();
  return <h3 className={classes.headerTwo}>{children}</h3>;
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italics>{text}</Italics>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) =>
      node.content.value !== '' && <Text>{children}</Text>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading>{children}</Heading>,
    [BLOCKS.HEADING_3]: (node, children) => <HeadingTwo>{children}</HeadingTwo>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const classes = useStyles();
      return node.data.target.file.contentType === 'image/svg+xml' ? (
        <img
          className={classes.vector}
          alt={node.data.target.file.fileName}
          src={node.data.target.file.url}
        />
      ) : (
        <div className={classes.resultWrapper}>
          <Img className={classes.result} fluid={node.data.target.fluid} />
        </div>
      );
    },
  },
};

const ProjectBody = ({ data, title, website, repo }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div className={classes.pageWrapper}>
      <HeroTwo title={title} />
      <Container>
        <div className={classes.richTextWrapper}>
          {data && renderRichText(data, options)}
        </div>
      </Container>
      <div className={classes.resultSection}></div>
      <div className={classes.fabWrapper}>
        <Fab
          className={classes.fab}
          color="primary"
          variant={!matches && 'extended'}
          aria-label="view code"
          component={'a'}
          href={repo}
          rel="noopener noreferrer"
          target="_blank"
        >
          <GitHubIcon className={classes.fabIcon} />
          {!matches && 'View Code'}
        </Fab>
        <Fab
          className={classes.fab}
          color="secondary"
          variant={!matches && 'extended'}
          aria-label="view site"
          component={'a'}
          href={website}
          rel="noopener noreferrer"
          target="_blank"
        >
          <DesktopWindowsIcon className={classes.fabIcon} />
          {!matches && 'Visit Site'}
        </Fab>
      </div>
    </div>
  );
};

export default ProjectBody;
